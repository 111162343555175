window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2');
(function ($, customMsg) {
    let $body = $('body');
    let $gotop = $('.gotop');
    let isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;

    // IE關閉smooth scroll
    if (navigator.userAgent.match(/Trident\/7\./)) {
        document.body.addEventListener("mousewheel", function () {
            event.preventDefault();
            let wd = event.wheelDelta;
            let csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }

    $('.menu li').has('ul').addClass('has-child');
    $('.menu li.has-child > a').on('click', function(event) {
        $(this).parent().toggleClass('open');
        $(this).parent().siblings('li').removeClass('open');
        $(this).siblings('ul').stop().slideToggle(400);
        $(this).parent().siblings('li').children('ul').stop().slideUp(400);
        return false;
    });

    $('.nav-toggle').click(function (event) {
        $body.toggleClass('nav-open');
        $('.menu li.open').removeClass('open');
        $('.menu li.has-child').children('ul').stop().slideUp(400);
        return false;
    });

    $gotop.click(function (event) {
        $('html,body').stop().animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    $(window).on('load', function () {
        var hashTarget = window.location.hash;
        window.location.hash = "";
        if (hashTarget && $(hashTarget).is(':visible')) {
            var pos = $(hashTarget).offset().top;
            // console.log(window.location.hash, pos);
            $('html,body').scrollTop(pos);
        }
        var $navList = $('.nav .menu');
        var sectPos;
        $(window).on('resize', function (event) {
            sectPos = [];
            $navList.find('.anchor').each(function (index, el) {
                var tId = $(el).attr('href').split("#")[1];
                // console.log(tId);
                var t = '#' + tId;
                if ($(t).length) {
                    sectPos.push({
                        'title': tId,
                        'pos': $(t).offset().top - 10
                    });
                }
            });
            sectPos.push({
                'title': 'footer',
                'pos': $('.footer').offset().top - 10
            });
        }).resize();
        // console.log(sectPos);

        $(window).on('scroll', function (event) {
            if ($(window).scrollTop() >= $('body').outerHeight() - $(window).height() - $('#' + sectPos[sectPos.length - 2]['title']).outerHeight()) {
                $('.anchor').parent('li').removeClass('current');
                $('.anchor[href$=' + sectPos[sectPos.length - 2]['title'] + ']').parent('li').addClass('current');
            } else {
                for (var i = 0; i < sectPos.length - 1; i++) {
                    if ($(window).scrollTop() >= sectPos[i]['pos'] && $(window).scrollTop() < sectPos[i + 1]['pos']) {
                        $('.anchor').parent('li').removeClass('current');
                        $('.anchor[href$=' + sectPos[i]['title'] + ']').parent('li').addClass('current');
                    }
                }
            }
        }).scroll();

        $('.anchor').on('click', function (event) {
            event.preventDefault();
            var tId = $(this).attr('href').split("#")[1];
            var t = '#' + tId;
            // console.log(t)
            var delay = 0;
            if ($(this).parents('.nav').length > 0) {
                $('.nav .menu li').removeClass('open');
                $('.menu li.has-child').children('ul').stop().slideUp(400);
                $('body').removeClass('nav-open');
                delay = 400;
            }
            $(this).parent('li').addClass('current');
            $(this).parent('li').siblings().removeClass('current');
            var pos = $(t).offset().top + 10;
            // if ($('.header .site-title .txt').is(':hidden')) {
            //   pos -= $('.header').outerHeight() - 1;
            // } else {
            //   pos += 2;
            // }
            $('html,body').stop().delay(delay).animate({
                scrollTop: pos
            }, 400);
        });
    });
    let lastScrollTop = $(window).scrollTop();
    let st = $(window).scrollTop();
    $(window).on('load', function () {
        $('.anime').each(function () {
            let currSect = $(this);
            $('.anime').each(function () {
                let currSect = $(this);
                if (currSect.offset().top - $(window).height() <= st) {
                    currSect.addClass("anime-run");
                }
            });
        });
    });
    $(window).on('scroll', function (event) {
        st = $(window).scrollTop();
        if (st > lastScrollTop) {
            $('.anime').each(function () {
                let currSect = $(this);
                if (currSect.offset().top - $(window).height() * 0.7 <= st && currSect.offset().top + $(window).height() * 0.7 > st) {
                    currSect.addClass("anime-run");
                }
            });
        } else {
            $('.anime').each(function () {
                let currSect = $(this);
                if (currSect.offset().top - $(window).height() * 0.5 <= st && currSect.offset().top > st) {
                    currSect.addClass("anime-run");
                }
            });
        }
        lastScrollTop = st;
    });
})($, customMsg);
const customMsg = window.customMsg = function (options) {
    var inner = '';

    if (options.title) inner += `<div class="result-title">${options.title}</div>`;
    if (options.text) inner += `<div class="result-txt">${options.text}</div>`;
    if (options.customButton) {
        inner += `<div class="result-btn">${options.customButton}</div>`;
    } else {
        inner += `<div class="result-btn"><button class="btn btn-default result-close" type="button">${options.confirmButtonText?options.confirmButtonText:'OK'}</button></div>`;
    }
    if ($('.result').length > 0) {


        $('.result-inner').html(inner);
    } else {
        var html =
            `<div class="result">
            <div class="result-content">
                <button class="btn result-close-btn result-close" type="button"><i class="ic ic-close"></i></button>
                <div class="result-inner">${inner}</div>
            </div>
        </div>`;
        $('body').append(html);
    }
    $(document).on('click', function (e) {
        if ($(e.target).parents('.result').length == 0 || $(e.target).parents('.result-close').length > 0 || $(e.target).hasClass('result-close')) {
            $('body').removeClass('result-open');
        }
    });
    $('body').addClass('result-open');
};
